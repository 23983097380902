/**
 */

import {Component} from 'react';

import ContentWrapper from 'SourceComponent/ContentWrapper';
import {ReactElement} from 'SourceType/Common.type';

import './Footer.style';

import {FooterComponent as SourceFooterComponent} from 'SourceComponent/Footer/Footer.component';
import NewsletterSubscription from "Component/NewsletterSubscription";
import CmsBlock from "Component/CmsBlock";
import {NEWSLETTER_COLUMN} from "Component/Footer/Footer.config";
import RenderWhenVisible from "Component/RenderWhenVisible";

/**
 * Page footer
 * @class Footer
 * @namespace Component/Footer/Component
 */
export class FooterComponent extends SourceFooterComponent {

    renderCopyrightContent(): ReactElement {
        const {copyright} = this.props;

        return (
            <ContentWrapper
                mix={{block: 'Footer', elem: 'CopyrightContentWrapper'}}
                wrapperMix={{block: 'Footer', elem: 'CopyrightContent'}}
                label=""
            >
                <span block="Footer" elem="Copyright">
                    {copyright}
                </span>
            </ContentWrapper>
        );
    }

    // Override and just output hardcoded stuff
    renderContent(): ReactElement {
        return (
            <div block="Footer" elem="Columns">
                <link rel="stylesheet" href=
                    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.css">
                </link>
                <div className="Column">
                    <div className="ColumnTitle"><h3>Kontakta oss</h3>
                        <li><a href="mailto:reservdelar@maskinia.se">reservdelar@maskinia.se</a></li>
                        <p>Maskinia AB</p>
                        <p>556260-8603</p>
                        <p>Nyckelgatan 2-6</p>
                        <p>589 41 Linköping</p>
                        <h3>Sociala medier</h3>
                        <h2 className="sm-btn">
                            <a href="https://www.facebook.com/maskiniaab" target="_blank" data-toggle="tooltip"
                               title="Facebook" data-placement="bottom"><i className="fab fa-facebook"></i></a>
                            <a href="https://www.instagram.com/maskinia_ab/" target="_blank" data-toggle="tooltip"
                               title="Instagram" data-placement="bottom"><i className="fab fa-instagram"></i></a>

                            <a href="https://www.youtube.com/user/Doosanswe" target="_blank" data-toggle="tooltip"
                               title="YouTube" data-placement="bottom"><i className="fab fa-youtube fa-md"></i></a>
                        </h2>
                        <h3>Viktiga länkar</h3>
                        <ul>
                            <li><a href="https://www.maskinia.se/foretaget/integritetspolicy">Integritetspolicy</a></li>
                            <li><a href="https://www.maskinia.se/kontakt">Kontakta oss</a></li>
                        </ul>
                    </div>
                </div>
                <div className="Column col-sm-6"><h3>Navigering</h3>
                    <ul>
                        <div className="col-left" style={{float: 'left'}}>
                            <li><a href="https://www.maskinia.se/">Hem</a></li>
                            <li><a href="https://www.maskinia.se/doosan">Köp Doosan/Develon</a></li>
                            <li><a href="https://www.maskinia.se/case">Köp
                                Case</a></li>
                            <li><a href="https://www.maskinia.se/bergmann">Köp
                                Bergmann</a></li>
                            <li><a href="https://www.maskinia.se/kop-redskap--tillbehor">Köp
                                Redskap &amp; Tillbehör</a></li>
                                <li><a href="https://www.maskinia.se/eftermarknad">Eftermarknad</a></li>
                                <li><a href="http://dealers.mascus.com/maskinia/search.aspx?pagesize=all#">Begagnat</a>
                                </li>
                            </div>
                        <div className="col-left" style={{float: 'left'}}>
                            <li><a href="https://www.maskinia.se/nyheter--media">Nyheter &amp; Media</a></li>
                            <li><a href="https://www.maskinia.se/kundreferenser">Kundreferenser</a></li>
                            <li><a href="https://www.maskinia.se/foretaget">Företaget</a></li>
                            <li><a href="https://www.maskinia.se/kontakt">Kontakt</a></li>
                            <li><a href="https://www.maskinia.se/offert">Offert</a>
                            </li>
                            <li><a href="/kundservice">Kundservice</a></li>
                            <li><a href="https://maskinia.profilservice.se/" target="_blank">Webshop
                                Profilprodukter</a>
                            </li>
                        </div>
                    </ul>
                </div>
                <div className="Column">
                    <img src="https://www.maskinia.se/assets/images/logo-primary.svg"/>
                    <li><a className="btn btn-primary" href="https://eu.doosanequipment.com/en/"
                           target="_blank">Doosan/Develons internationella sida</a>
                    </li>
                    <li><a className="btn btn-primary" href="https://www.casece.com/emea/en-eu"
                           target="_blank">Cases internationella sida</a></li>
                    <li><a className="btn btn-primary" href="https://www.bergmann-dumper.de/en/"
                           target="_blank">Bergmanns internationella sida</a></li>
                </div>
            </div>
        );
    }

    //Add gtm body script.
    renderGTM() {
        //Dont add gtm for develop.
        if (window.location.hostname == 'localhost') {
            return null;
        }
        const gtm = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NFTHRFPX" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
        return (<>
            <noscript>{gtm}</noscript>
        </>);
    }

    //Add call to renderGTM
    render(): ReactElement {
        const {isVisibleOnMobile, device} = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <RenderWhenVisible>
                <footer block="Footer" aria-label="Footer">
                    {this.renderGTM()}
                    {this.renderContent()}
                    {this.renderCopyrightContent()}
                </footer>
            </RenderWhenVisible>
        );
    }

}

export default FooterComponent;
