Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/node_modules/@scandiweb/adobe-page-builder/src/plugin/content-type/Buttons.plugin.js'),require('/drone/src/build/code/scandipwa/node_modules/@scandiweb/adobe-page-builder/src/plugin/content-type/Map.plugin.js'),require('/drone/src/build/code/scandipwa/node_modules/@scandiweb/adobe-page-builder/src/plugin/content-type/ProductSlider.plugin.js'),require('/drone/src/build/code/scandipwa/node_modules/@scandiweb/adobe-page-builder/src/plugin/content-type/Slider.plugin.js'),require('/drone/src/build/code/scandipwa/node_modules/@scandiweb/adobe-page-builder/src/plugin/content-type/Tab.plugin.js'),require('/drone/src/build/code/scandipwa/node_modules/@scandiweb/adobe-page-builder/src/plugin/html/HtmlComponent.plugin.js')]);
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-useless-fragment */
/**
 Override handling for links. Special for bit.ly
 */

/* eslint-disable consistent-return */
// Disabled due `domToReact` internal logic
import parser, {DomElement, HTMLReactParserOptions} from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import Link from 'SourceComponent/Link';
import {HIGH_PRIORITY_ELEMENTS, HtmlComponent as SourceHtmlComponent} from 'SourceComponent/Html/Html.component';
import {setLoadedFlag} from "Util/Request/LowPriorityLoad";
import {AfterPriority} from "Util/Request/LowPriorityRender";

// Used to load LCP elements as high priority

/**
 * Html content parser
 * Component converts HTML strings to React components
 * @class Html
 * @namespace Component/Html/Component
 */
export class HtmlComponent extends SourceHtmlComponent {

    //Maskinia - add id to div that show error.
    parserOptions: HTMLReactParserOptions = {
        // eslint-disable-next-line react/no-unstable-nested-components
        replace: (domNode: DomElement): JSX.Element | undefined => {
            const {
                data,
                name: domName,
                attribs: domAttrs,
                parent,
                next,
                children = [],
            } = domNode;

            if (!parent && !next && !this.lastBlock) {
                if (children.length) {
                    this.lastBlock = this.getLastRenderElement(children[children.length - 1]);
                } else {
                    this.lastBlock = this.getLastRenderElement(domNode);
                }
            }

            if (this.lastBlock === domNode && !this.isPriorityLoading) {
                setLoadedFlag();
            }

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const {query: {name, attribs = []}} = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const {replace} = rule;

                if (this.isPriorityLoading) {
                    return (
                        <AfterPriority fallback={<div className={"MaskiniaSomethingWrong"} style={{height: '100vh'}}/>}>
                            {replace.call(this, domNode)}
                        </AfterPriority>
                    );
                }

                if (rule.query.name.some((name) => HIGH_PRIORITY_ELEMENTS.includes(name)) && !this.isPriorityLoading) {
                    this.isPriorityLoading = true;
                }

                return replace.call(this, domNode);
            }
        },
    };


    /**
     * Replace links to native React Router links
     * @param  {{ attribs: Object, children: Array }}
     * @return {void|JSX} Return JSX if link is allowed to be replaced
     * @memberof Html
     */
    replaceLinks({attribs, children}: DomElement): JSX.Element {
        const {href, ...attrs} = attribs;
        if (href) {
            const isAbsoluteUrl = (value: string) => /^(?:[a-z]+:)?\/\//i.test(value);
            const isSpecialLink = (value: string) => /^(sms|tel|mailto):/i.test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link
                        onClick={this.scrollToTopFunction}
                        {...attributesToProps({...attrs, to: href})}
                    >
                        {domToReact(children, this.parserOptions)}
                    </Link>
                );
            }

            //Maskinia - fix problem with extern links to bit.ly
            if (href.includes('https://bit.ly')) {
                return (
                    <Link
                        onClick={this.scrollToTopFunction}
                        {...attributesToProps({...attrs, to: href})}
                    >
                        {domToReact(children, this.parserOptions)}
                    </Link>
                );
            }
        }

        return domToReact(children, this.parserOptions) as JSX.Element;
    }
}

export default HtmlComponent;
